<script setup lang="ts">
import type { DialogBreakpoints } from 'primevue/dialog';
import Button from './button.vue';
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import { trackingMethods } from '~/plugins/analytics.client';
import Dialog from 'primevue/dialog';
interface IModalProps {
  title?: string;
  breakpoints?: DialogBreakpoints;
  width?: string;
  active?: boolean;
  contentType?: 'form' | 'content';
  displayBackground?: boolean;
  closeModalOption?: boolean;
  displayBorder?: boolean;
  headerIcon?: Component;
  headerIconClass?: string;
  iconTooltip?: string;
}
const { $t }: any = useNuxtApp();
const emits = defineEmits(['closeModal']);

const props = withDefaults(defineProps<IModalProps>(), {
  title: '',
  contentType: 'form',
  displayBackground: true,
  closeModalOption: true,
  displayBorder: true,
  headerIcon: null,
  headerIconClass: '',
  iconTooltip: '',
});

const displayModal: globalThis.Ref<boolean> = ref(false);
const openModal = () => {
  if (!props.active) {
    displayModal.value = true;
  }
};
const closeModal = () => {
  displayModal.value = false;
  unlockBodyScroll();
  const registerModal = $t('jpc-register');
  if (props.title === registerModal) {
    trackingMethods.registerAbandoned();
  }
};
const width = computed(() => {
  if (props.width) return props.width;
  if (props.contentType === 'form') return '480px';
  if (props.contentType === 'content') return '800px';
});

const classes = (): string => {
  const baseClasses = 'hide-scrollbar overflow-hidden';
  return props.displayBackground ? ' ' + baseClasses : baseClasses;
};

const contentClasses = computed(() => {
  const borderless = props.displayBorder ? '' : ' borderless';
  const transparent = props.displayBackground ? '' : 'bg-transparent';
  return borderless + ' ' + transparent;
});

watchEffect(() => {
  if (props.active === true) {
    switch (props.title) {
      // send GA tracking method for register modal loaded
      case $t('jpc-register'):
        trackingMethods.registerLoaded();
        break;
      // send GA tracking method for search modal loaded
      case $t('search-games'):
        trackingMethods.toggleSearch();
        break;
      default:
        break;
    }
  }
});
</script>

<template>
  <div>
    <div @click="openModal">
      <slot name="modal-activator"></slot>
    </div>
    <Dialog
      :modal="true"
      :closable="false"
      :draggable="false"
      :breakpoints="props.breakpoints"
      :style="{ maxWidth: 'calc(100vw - 0.5rem)', width }"
      :visible="props.active ? props.active : displayModal"
      :class="classes"
      :content-class="contentClasses"
      style="max-height: 90vh"
      @show="lockBodyScroll()"
      @hide="unlockBodyScroll()"
      :show-header="!!props.title"
      :dismissable-mask="true"
      append-to="body"
    >
      <template v-if="props.title" #header>
        <div class="flex flex-row mr-2 align-items-center">
          <component
            v-if="props.headerIcon"
            :is="props.headerIcon"
            :class="props.headerIconClass"
            :tooltip="props.iconTooltip"
          />
        </div>
        <div
          class="flex flex-row justify-content-between w-full align-content-center"
        >
          <h2 class="h-fit">
            {{ title }}
          </h2>
          <Button
            v-if="props.closeModalOption"
            @click="$emit('closeModal'), closeModal()"
            group="tertiary-icon"
            icon="pi pi-times"
            size="medium"
          />
        </div>
      </template>
      <slot name="modal-content" />

      <template v-if="!!$slots['footer-content']" #footer>
        <slot name="footer-content"></slot>
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss">
.dark {
  .p-dialog .p-dialog-footer {
    background: #131217 !important;
    padding: 0px;
  }
}

.p-dialog {
  box-shadow: none !important;
  border-radius: 8px;
  position: absolute;
  top: 105px;

  @media screen and (min-width: 641px) and (max-width: 1366px) {
    top: 50px;
  }
  @media (max-width: 640px) {
    top: 5px !important;
  }

  //TODO: remove comment below if no issues with it
  // Changed to 767 from 768 to fix account options styling
  & .p-dialog-header {
    background: #eceff6 !important;
    padding: 12px 15px !important;
    border: 1px solid #dae0ed !important;
    border-bottom: 0;
    .dark & {
      background-color: #121417 !important;
      border-color: #464f60 !important;
      border-bottom: 0;
    }

    & h2 {
      font-family: Inter;
      font-size: 16px;
      margin-top: 0.3rem;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.5;
      text-align: left;
      color: #2b303b;

      .dark & {
        color: #ffffff;
      }
    }

    & .p-button.p-button-icon-only {
      width: 32px;
    }
  }

  & .p-dialog-content {
    background: #fff;
    padding: 0px 0px 0px 0px !important;
    border: 1px solid var(--light-light-400, #dae0ed);
    border-top: none !important;

    .dark & {
      border: 1px solid var(--dark-dark-500, #464f60);
    }

    &.borderless {
      border: none !important;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    .dark & {
      background: #1d2129;
    }
  }

  & .p-dialog-footer {
    background: #f4f4f6 !important;
  }
}
</style>
